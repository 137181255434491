import React, { useEffect, useState, useContext } from "react";
import useAxios from "../../CustomHooks/useAxios";
import { UserContext } from "../../context/UserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CircularProgress from "@mui/material/CircularProgress";
import { GlobalContext } from "../../context/Provider";
import SnackBarComponent from "../SnackBarComponent/SnackBarComponent";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [callAPI] = useAxios();
  const { user } = useContext(UserContext);
  const { setOpenSnackBar } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    idpObjectId: "",
    email: "",
    customerId: "",
    reportRole: "View",
    explorerRoles: ["User"],
  });
  const [customers, setCustomers] = useState([]);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (!loadingUsers || !user) return; // Prevent multiple calls and ensure user is loaded
      if (user?.explorerRoles.includes("Admin")) {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const customers = await callAPI(`${apiUrl}/admin/customers`, "GET");
          setCustomers(customers);
          const users = await callAPI(`${apiUrl}/admin/users`, "GET");
          setUsers(users);
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingUsers(false);
        }
      } else {
        console.log("You do not have permission to view this page.");
      }
    };

    fetchData();
  }, [loadingUsers, user, users]);

  const handleAddUser = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await callAPI(`${apiUrl}/admin/users`, "POST", newUser);
      setUsers((prevUsers) => [...prevUsers, response]);
      setLoadingUsers(true);
      setApiResponse(response);
      setResponseDialogOpen(true);
      setOpen(false);
    } catch (error) {
      setApiResponse({ error: error.message });
      setOpenSnackBar({
        open: true,
        message: apiResponse,
        severity: "error",
      });
      setResponseDialogOpen(false);
      setLoadingUsers(false);
    }
  };

  const handleDeleteUser = async (idpObjectId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await callAPI(`${apiUrl}/admin/users/${idpObjectId}`, "DELETE");
      setUsers((prevUsers) => prevUsers.filter(user => user.idpObjectId !== idpObjectId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = (customerId) => {
    setNewUser((prevUser) => ({
      ...prevUser,
      customerId: customerId,
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  return (
    <div className="page">
      <h1>User Management</h1>
      {loadingUsers ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </div>
      ) : user?.explorerRoles.includes("Admin") ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Org</TableCell>
                  <TableCell>Report Role</TableCell>
                  <TableCell>Explorer Roles</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <React.Fragment key={customer.customerName}>
                    <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell colSpan={4} style={{ fontWeight: "bold" }}>
                        {customer.customerName}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleClickOpen(customer.customerId)}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {users
                      .filter(
                        (user) => user.customerName === customer.customerName
                      )
                      .map((usr, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            &nbsp;&nbsp;&nbsp;&nbsp;{usr.email}
                          </TableCell>
                          <TableCell>{customer.customerName}</TableCell>
                          <TableCell>{usr.reportRole}</TableCell>
                          <TableCell>
                            {usr.explorerRoles?.join(", ")}
                          </TableCell>
                          <TableCell>
                            {usr.idpObjectId !== user.idpObjectId && (
                              <IconButton onClick={() => handleDeleteUser(usr.idpObjectId)} style={{ color: "red" }}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please fill in the details for the new user.
              </DialogContentText>

              <TextField
                margin="dense"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                value={newUser.email}
                onChange={handleChange}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="customer-select-label">Customer</InputLabel>
                <Select
                  labelId="customer-select-label"
                  id="customer-select"
                  label="Customer"
                  name="customerId"
                  value={newUser.customerId}
                  onChange={handleChange}
                >
                  {customers.map((customer) => (
                    <MenuItem
                      key={customer.customerId}
                      value={customer.customerId}
                    >
                      {customer.customerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="reportRole-select-label">
                  Report Role
                </InputLabel>
                <Select
                  labelId="reportRole-select-label"
                  id="reportRole-select"
                  label="Report Role"
                  name="reportRole"
                  value={newUser.reportRole}
                  onChange={handleChange}
                >
                  <MenuItem value="View">View</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="explorerRoles-select-label">
                  Explorer Roles
                </InputLabel>
                <Select
                  labelId="explorerRoles-select-label"
                  id="explorerRoles-select"
                  label="Explorer Roles"
                  multiple
                  name="explorerRoles"
                  value={newUser.explorerRoles}
                  onChange={handleChange}
                >
                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddUser} color="primary">
                Add User
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={responseDialogOpen} onClose={() => setResponseDialogOpen(false)}>
            <DialogTitle>New User</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
                {apiResponse.error ? (
                  `Error: ${apiResponse.error}`
                ) : (
                  <>
                    User added successfully!

                    Be sure to copy the following information and send it to the person:

                    <br />
                    <b>Email:</b> {apiResponse.email}
                    <br />
                    <b>Password:</b> {apiResponse.password}
                  </>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setResponseDialogOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <SnackBarComponent />
        </>
      ) : (
        <p>You do not have permission to view this page.</p>
      )}
    </div>
  );
};

export default UserManagement;
