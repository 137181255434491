import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './index.css';
import { GlobalProvider } from "./context/Provider";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { createRoot } from 'react-dom/client';
import { msalConfig } from './authConfig';
import { UserProvider } from "./context/UserContext";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <GlobalProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </GlobalProvider>
    </MsalProvider>
  </React.StrictMode>
);