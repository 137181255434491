import { loginRequest } from "./../authConfig";

export function signInClickHandler(instance) {
  instance.loginRedirect(loginRequest);
}

export function signOutClickHandler(instance, account) {
  const logoutRequest = {
    account: account,
    postLogoutRedirectUri: window.location.origin,
  };
  instance.logoutRedirect(logoutRequest);
}
