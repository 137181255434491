import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const withAuthRedirect = (WrappedComponent) => {
  return (props) => {
    const { instance, accounts, inProgress } = useMsal();

    const ensureLoggedIn = async () => {
      await instance.handleRedirectPromise();
      if (accounts.length === 0) {
        instance.loginRedirect(loginRequest);
      }
    };

    useEffect(() => {
      ensureLoggedIn();
    }, []);

    return accounts.length > 0 ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuthRedirect;