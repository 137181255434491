import { Alert, Slide, Snackbar } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/Provider'

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

const SnackBarComponent = () => {
    const {openSnackBar, setOpenSnackBar} = useContext(GlobalContext)

    const handleClose = () =>{
        setOpenSnackBar({...openSnackBar, open:false})
    }

    return (
        <div>
            <Snackbar open={openSnackBar.open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={TransitionLeft} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity= {openSnackBar.severity} variant="filled" sx={{ width: '100%' }}>
                    {openSnackBar.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SnackBarComponent
