import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {
    let navigate = useNavigate()

    const handleBackClick = () => {
        navigate('/')
    }

  return (
    <div style={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center" }}>
            <div style={{textAlign: 'center'}}>
                <h1 style={{fontSize: "7rem"}}>404 Error</h1>
                <p style={{fontSize: "2rem"}} >Looks like you are lost</p>
                <Button onClick={handleBackClick} variant='contained' color='primary'>Go Back Home</Button>
            </div>

        </div>
  )
}

export default ErrorPage