import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Card, CardMedia, CardContent, CardActions, Button, Typography, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import fmd_thumbnail from '../../assets/images/report-thumbnails/fmd.png';

const Home = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="page">
      <h1>Welcome to Aidon Explorer</h1>
      <p>Select a report to get started.</p>

      <h2>Your reports</h2>
      <Grid container spacing={3}>
        {user?.reports.map((report) => (
          <Grid item xs={12} sm={6} md={4} key={report.id}>
            <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 140 }}
                image={fmd_thumbnail}
                title="FMD Trafo Report"
            />
              <CardContent>
                <Typography variant="h6" component="div">
                  {report.reportName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {user.customerName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {report.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={NavLink} to={`/reports/${report.id}`}>
                  View Report
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;