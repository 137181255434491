import React from "react";
import './App.css';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Header from "./Components/Header/Header";
import SnackBarComponent from "./Components/SnackBarComponent/SnackBarComponent";
import withAuthRedirect from "./withAuthRedirect";
import PageWrapper from "./Components/PageWrapper/PageWrapper";
import { UserProvider } from "./context/UserContext";

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthenticatedTemplate>
          <UserProvider>
            <BrowserRouter>
              <Header />

              <Routes>
                <Route path='/*' element={<PageWrapper />} />
                <Route path='/error' element={<ErrorPage />} />
                <Route path="*" element={<Navigate to="/error" />} />
              </Routes>
              <SnackBarComponent />
            </BrowserRouter>
          </UserProvider>
        </AuthenticatedTemplate>
      </ThemeProvider>
    </>
  );
};

export default withAuthRedirect(App);