import { useMsal } from '@azure/msal-react';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/aidon_explorer.png';
import { signOutClickHandler } from '../../services/AuthService';
import { UserContext } from '../../context/UserContext';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Header() {
    const { accounts, instance } = useMsal();
    const { user } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        signOutClickHandler(instance, accounts[0]); // Pass the correct account
        navigate('/'); // Redirect to login page
    };

    return (
        <div>
            <div style={{ height: 50, padding: "0 18px", display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <div> <img src={logo} alt='Aidon Explorer' style={{ height: 30, marginTop: 5 }}/> </div>
                <div>
                    <span onClick={handleMenuOpen} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                        {accounts[0]?.idTokenClaims.name}
                        {anchorEl ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </span>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>
                            <LogoutIcon />&nbsp;&nbsp;Logout
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default Header;