import React, { createContext, useReducer, useState} from 'react';
import favInitialState from './initialState/favInitialState';
import favs from './redusers/favs';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
    const [favState, favDispatch] = useReducer(favs, favInitialState);
    const [openSnackBar, setOpenSnackBar] = useState({
        open: false,
        message: "",
        severity: "success"
    })
   
    return (
        <GlobalContext.Provider
            value={{
                favState,
                favDispatch,  
                openSnackBar, 
                setOpenSnackBar              
            }}>
            {children}
        </GlobalContext.Provider>
    )
}