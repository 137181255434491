import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
      button: { textTransform: 'none'},
      fontFamily: "Montserrat"
     }, 
    // palette: {
    //     primary:{
    //       main: "#F7C936",
    //       light: "#FFE090",
    //     },
    //     secondary: {
    //       main: "#373E41"
    //     }
    //   },
    });