import React, { useEffect, useState, useContext } from "react";
import useAxios from "../../CustomHooks/useAxios";
import { UserContext } from "../../context/UserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [callAPI] = useAxios();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!loadingCustomers || !user) return; // Prevent multiple calls and ensure user is loaded
      if (user?.explorerRoles.includes("Admin")) {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const customers = await callAPI(`${apiUrl}/admin/customers`, "GET");
          setCustomers(customers);
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingCustomers(false);
        }
      } else {
        console.log("You do not have permission to view this page.");
      }
    };

    fetchData();
  }, [loadingCustomers, user]);

  return (
    <div className="page">
      <h1>Customer Management</h1>
      {loadingCustomers ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </div>
      ) : user?.explorerRoles.includes("Admin") ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Reports assigned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow key={customer.customerId}>
                    <TableCell>{customer.customerName}</TableCell>
                    <TableCell>{customer.customerId}</TableCell>
                    <TableCell>
                      {customer.reports.map((report) => (
                        <Box key={report.reportId} component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                          <b>Name:</b> {report.reportName}<br/>
                          <b>Report ID:</b> {report.reportId}<br/>
                          <b>Workspace ID:</b> {report.workspaceId}
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <p>You do not have permission to view this page.</p>
      )}
    </div>
  );
};

export default CustomerManagement;