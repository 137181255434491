const favs = (state, action) => {
    switch(action.type){        
        case "SET_NAME":
            return {...state, name: action.payload};
        case "SET_CUSTOMER_ID":
            return {...state, userName: action.payload};

        
        default:
            return state;
    }
}

export default favs